<template>
  <v-dialog v-model="dialog" scrollable max-width="800" @click:outside="reset">
    <v-card>
      <v-card-title class="headline">
        {{ isEditing ? "Edit" : "Add a new" }} Customer
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
        <v-form @submit.prevent="save" method="post" id="customer-form">
          <v-text-field
            label="First Name *"
            v-model="fields.first_name"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('first_name')"
            :error-messages="errors['first_name']"
          ></v-text-field>
          <v-text-field
            label="Last Name *"
            v-model="fields.last_name"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('last_name')"
            :error-messages="errors['last_name']"
          ></v-text-field>
          <v-text-field
            label="Email *"
            v-model="fields.email"
            type="email"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('email')"
            :error-messages="errors['email']"
          ></v-text-field>
          <v-text-field
            label="Phone *"
            v-model="fields.phone"
            type="phone"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('phone')"
            :error-messages="errors['phone']"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="reset()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="customer-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import * as countries from "i18n-iso-countries";
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

export default {
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      customer: {},
      errors: {},
      fields: {
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        billing_address_company: null,
        billing_address_line_1: null,
        billing_address_line_2: null,
        billing_address_city: null,
        billing_address_postcode: null,
        billing_address_county: null,
        billing_address_country: "United Kingdom",
        shipping_address_company: null,
        shipping_address_line_1: null,
        shipping_address_line_2: null,
        shipping_address_city: null,
        shipping_address_postcode: null,
        shipping_address_county: null,
        shipping_address_country: "United Kingdom",
      },
    };
  },

  computed: {
    availableCountries() {
      return Object.values(countries.getNames("en"));
    },
  },

  methods: {
    open(customer = null) {
      if (customer !== null) {
        this.customer = customer;
        this.isEditing = true;
        this.fields.first_name = customer.first_name;
        this.fields.last_name = customer.last_name;
        this.fields.email = customer.email;
        this.fields.phone = customer.phone;

        //   if (customer.billing_address)
        //     this.fields.billing_address_company =
        //       customer.billing_address.company;
        //   this.fields.billing_address_line_1 = customer.billing_address.line_1;
        //   this.fields.billing_address_line_2 = customer.billing_address.line_2;
        //   this.fields.billing_address_city = customer.billing_address.city;
        //   this.fields.billing_address_postcode =
        //     customer.billing_address.postcode;
        //   this.fields.billing_address_county = customer.billing_address.county;
        //   this.fields.billing_address_country = customer.billing_address.country;
        // }

        // if (customer.shipping_address) {
        //   this.fields.shipping_address_company =
        //     customer.shipping_address.company;
        //   this.fields.shipping_address_line_1 = customer.shipping_address.line_1;
        //   this.fields.shipping_address_line_2 = customer.shipping_address.line_2;
        //   this.fields.shipping_address_city = customer.shipping_address.city;
        //   this.fields.shipping_address_postcode =
        //     customer.shipping_address.postcode;
        //   this.fields.shipping_address_county = customer.shipping_address.county;
        //   this.fields.shipping_address_country =
        //     customer.shipping_address.country;
      }
      this.dialog = true;
    },

    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.customerId = this.customer.id;
      }

      this.$store
        .dispatch("arnprior/customersStore/saveCustomer", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.customer = {};
      this.fields.full_name = null;
      this.fields.email = null;
      this.fields.phone = null;
      this.fields.billing_address_company = null;
      this.fields.billing_address_line_1 = null;
      this.fields.billing_address_line_2 = null;
      this.fields.billing_address_city = null;
      this.fields.billing_address_postcode = null;
      this.fields.billing_address_county = null;
      this.fields.billing_address_country = "United Kingdom";
      this.fields.shipping_address_company = null;
      this.fields.shipping_address_line_1 = null;
      this.fields.shipping_address_line_2 = null;
      this.fields.shipping_address_city = null;
      this.fields.shipping_address_postcode = null;
      this.fields.shipping_address_county = null;
      this.fields.shipping_address_country = "United Kingdom";
      this.$refs.formWrapper.scrollTop = 0;
    },
  },
};
</script>
